import ApiActions from '../actions/api';
import { getLocalService } from '../components/triggers/get-local-service';
import { noop } from '../props/empty';

export function callTriggerAction(
  entity,
  method,
  data,
  serviceVersion,
  callback = noop
) {
  if (serviceVersion === 'local') {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      let service = getLocalService(entity);
      // we use import with '*' for EventService because an es6 conflict and here we have to access 'default' property
      // if better solution will be found this need to change
      if (
        service &&
        !service[method] &&
        service['default'] &&
        service['default'][method]
      ) {
        service = service['default'];
      }
      await service[method].call(service, data);
      resolve();
    }).then(() => {
      callback();
    });
  }

  return ApiActions.post(
    { entity, method, data, callback: false, serviceVersion },
    true
  )
    .then(response => {
      if (
        response &&
        (response.redirect_url ||
          (response.result && response.result.redirect_url))
      ) {
        callback(response.redirect_url || response.result.redirect_url);
      } else {
        callback();
      }
    })
    .catch(error => {
      console.log(error);
      callback();
    });
}
