/* eslint-disable max-len */
import BaseValidator from './index';
import isObject from 'lodash.isobject';
import getConfig from '../../../config';

let instance = null;

/**
 * Field validators for different types of input
 */
class Validator extends BaseValidator {
  constructor() {
    super();

    instance = instance || this;

    return instance;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Validate an email to be in the correct format
   * @param {String} email to be validated
   * @returns {boolean}
   */
  isValidEmail(email) {
    // eslint-disable-next-line max-len
    const regex = /^(?!.*\.\.)([a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return regex.test(email);
  }

  // noinspection JSMethodCanBeStatic
  /* Validates that the user entered a valid "name". We define this as any
       Unicode string that fits in the BMP, excluding the first unprintable
       characters.
       https://en.wikipedia.org/wiki/Plane_(Unicode)#Basic_Multilingual_Plane
     */
  isValidName(str) {
    // Note that since JS uses surrogate pairs to represent characters, we can detect that by banishing the surrogate range (and the adjacent private use area): [D800-F900)
    return /^[\x20-\uD7FF|\uF900-\uFFFD}]*$/.test(str);
  }

  // noinspection JSMethodCanBeStatic
  isValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.trim();
    const regex = /^[\d ()\-+.]+$/;
    return regex.test(phoneNumber);
  }

  /**
   * Validate strong password
   * The password should be minimum 8 characters and should containt at least 1 upper case letter and 1 number
   * @param {String} password to be validated
   * @returns {boolean}
   */
  isValidPassword(password) {
    const regex = getConfig().passwordRegex;
    return regex.test(password);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Validate zip code
   * The zip code should be a 5 digit string
   * @param {String} zipCode zip code
   * @returns {boolean} true if zip code is valid, false otherwise
   */
  isValidZipCode(zipCode) {
    const regex = /^\d{5}$/;
    return regex.test(zipCode);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Validate postal code
   * The postal code should be a valid UK postal code
   * @param {String} postalCode uk postal code
   * @returns {Boolean}
   */
  isValidPostalCode(postalCode) {
    const regex =
      /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/;
    return regex.test(postalCode);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Checks if a parameter is empty (empty if null, undefined, empty string, empty object or empty array)
   * @param {Object} obj any type of entity
   * @returns {boolean} true if obj is empty, false otherwise
   */
  isEmpty(obj) {
    if (typeof obj === 'number') {
      return false;
    }

    if (!obj) {
      return true;
    }

    if (obj instanceof Date) {
      return false;
    }
    return typeof obj === 'object' && Object.keys(obj).length === 0;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Checks the obj value to be an array
   * @param {any} obj the obj to be checked
   * @returns {boolean} true if Array, false otherwise
   */
  isArray(obj) {
    return Array.isArray(obj);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Checks if the obj is a plain Object
   * @param {any} obj the obj to be checked
   * @returns {boolean} true if Object, false otherwise
   */
  isObject(obj) {
    return isObject(obj);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Checks if the value has a minimum required length
   * @param {String} value value to check minimum length for
   * @param {Number} minLength the minimum length to validate
   * @returns {Boolean}
   */
  isMinLength(value) {
    const regex = /^([a-zA-Z0-9_-]){4,}$/;
    return regex.test(value);
  }

  // noinspection JSMethodCanBeStatic
  hasMinLength(string, length) {
    const value = `${string.trim()}`;
    return value.length >= length;
  }

  // noinspection JSMethodCanBeStatic
  hasMaxLength(string, length) {
    const value = `${string.trim()}`;
    return value.length <= length;
  }

  // noinspection JSMethodCanBeStatic
  matchesRegex(value, regex) {
    return regex ? new RegExp(regex).test(value) : true;
  }
}

export default new Validator();
